import React from 'react';

import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

import Hero from '../Hero/Hero';
import ProductList from '../ProductsView/ProductList';
import { CBD_PRODUCT_TYPES } from '../../data/products';

import CBDDropsSrc from '../../images/cbd/CBD_Drops.png';

class ProductView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    return (
      <article className="ProductView">
        <Hero
          description={<>Check back in 2021 <br /> to shop Select CBD online.</>}
          image={CBDDropsSrc}
          imageHeight={593}
          imageWidth={732}
          title={<>Something better<br />is coming.</>}
        />
        <ProductList {...this.props} data={CBD_PRODUCT_TYPES} />
      </article>
    );
  }
}

export default withMedia(ProductView);
