import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Hero.scss';

const Hero = ({
  background,
  description,
  image,
  imageHeight,
  imageWidth,
  position,
  secondTitle,
  secondDescription,
  title,
}) => {
  const positionClasses = cx({
    content: true,
    'content--bottom': position && position.toLowerCase() === 'bottom',
    'content--middle': position && position.toLowerCase() === 'middle',
    'content--top': position && position.toLowerCase() === 'top',
  });

  return (
    <section className="hero" style={{ backgroundImage: `url(${background})` }}>
      <div className="tile">
        <div className={positionClasses}>
          <div className="hero__text">
            {title && <h2 className="hero__title">{title}</h2>}
            {description && <p className="hero__description">{description}</p>}
            {secondTitle && (
              <h2 className="hero__title hero__secondTitle">{secondTitle}</h2>
            )}
            {secondDescription && (
              <p className="hero__description">{secondDescription}</p>
            )}
          </div>
          {image && (
            <div className="hero__image">
              <img
                src={image}
                width={imageWidth}
                height={imageHeight}
                alt={title}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = {
  background: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  position: PropTypes.oneOf(['top', 'middle', 'bottom']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondTitle: PropTypes.object,
  secondDescription: PropTypes.object,
};

export default Hero;
